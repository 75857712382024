<template>
  <div class="footerMain">
    <div class="fullContents">
      <div class="fullContent">
        <div class="fullContentLeft">
          <div class="menus">
            <ul>
              <li><router-link to='/'> <a>首页</a> </router-link></li>
              <li><router-link to='/companyProfile'> <a>企业概况</a> </router-link></li>
              <li><router-link to='/productSeries'> <a>产品与技术</a> </router-link></li>
              <li><router-link to='/solution'> <a>解决方案</a> </router-link></li>
              <li><router-link to='/talentRecruitment'> <a>加入我们</a> </router-link></li>
              <li><router-link to='/pressCenter'> <a>新闻中心</a> </router-link></li>
              <li><router-link to='/contactUs'> <a>联系我们</a> </router-link></li>
            </ul>
          </div>
          <div class="aboutUsInfos">
            <p v-for="d in contactUs" :key="d">
              <span>{{ d.indexOf("022-") !== -1 ? '座机' : '手机' }}：
                <span :class="d.indexOf('022-') !== -1 ? 'enlargeFont': ''">{{ d }}</span>
              </span>
            </p>
            <p><span>邮箱：{{ companyEmail }}</span></p>
            <p><span>地址：{{ companyAddress }}</span></p>
          </div>
        </div>
        <div class="fullContentRight">
          <baiduMap></baiduMap>
        </div>
      </div>
    </div>
    <div class="footerInfos">
      Copyright © 2023 天津求实智源科技有限公司
      <span>网站备案号：
        <a class="copyrightText" :href="systemBottomInfoALink === '' ? '' : externalLinks">
          {{ systemBottomInfoALink }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import baiduMap from "@/components/baiduMap";

export default {
  name: "footerMain",
  data() {
    return {
      contactUs: contactUs,
      companyEmail: companyEmail,
      companyAddress: companyAddress,
      systemBottomInfoALink: systemBottomInfoALink,
      externalLinks: externalLinks
    }
  },
  components: {
    baiduMap,
  },
}
</script>

<style scoped lang="scss">
.footerMain {
  color: #999999;
  width: 100%;
  height: 100%;
  //display: table-cell;
  //vertical-align: middle;
  background-color: rgb(27, 27, 27);
  .fullContents {
    width: 100%;
    background: #2E2E2E;
    .fullContent {
      width: 1200px;
      overflow: hidden;
      display: inline-block;
      .fullContentLeft,
      .fullContentRight {
        width: 600px;
        display: inline-block;
        vertical-align: middle;
      }
      .fullContentLeft {
        .menus {
          border-bottom: 1px solid #232323;
          ul {
            height: 54px;
            line-height: 54px;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            li {
              float: left;
              height: 54px;
              padding: 0 14px;
              line-height: 54px;
              text-align: center;
              a {
                font-size: 14px;
                white-space: nowrap;
                display: block;
                padding: 0px;
                text-decoration: none;
                color: #ffffff;
                text-align: center;
                font-weight: normal;
                font-style: normal;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                  //color: #2a9be1;
                  color: rgb(255, 204, 0);
                }
              }
            }
          }
        }
        .aboutUsInfos {
          padding: 30px 0 0 0;
          text-align: left;
          p {
            padding: 4px 0;
            margin: 0;
            span {
              font-size: 14px;
            }
            .enlargeFont {
              font-size: 24px;
              color: rgb(255, 204, 0);
            }
          }
        }
      }
    }
  }
  .footerInfos {
    width: 100%;
    height: 46px;
    line-height: 46px;
    color: #888888;
    font-size: 12px;
    span {
      margin-left: 10px;
      .copyrightText,
      .copyrightText:visited{
        color: #888888;
        text-decoration: none;
      }
    }
  }
}
</style>
