<template>
  <BaiduMap
    class="mapView"
    ak="YOUR_APP_KEY"
    :center="center" :zoom="zoom"
    :scroll-wheel-zoom="true"
    :dragging="true" animation="BMAP_ANIMATION_BOUNCE"
  >
    <BmNavigation anchor="BMAP_ANCHOR_TOP_LEFT"></BmNavigation>
    <BmMapType :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></BmMapType>
    <BmMarker :position="MarkerCenter" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">

    </BmMarker>
    <BmInfoWindow :position="InfoWindowCenter" :width="140" :height="80" title="天津求实智源科技有限公司" :show="true">
      <p style="font-size: 12px;height: 48px">
        {{ companyAddress }}
      </p>
    </BmInfoWindow>
  </BaiduMap>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {BmNavigation, BmMapType, BmMarker, BmInfoWindow} from 'vue-baidu-map'


export default {
  name: "baiduMap",
  data () {
    return {
      center: mapCenter,
      MarkerCenter: mapMarkerCenter,
      InfoWindowCenter: mapInfoWindowCenter,
      zoom: 18,
      companyAddress: companyAddress,
    }
  },
  components: {
    BaiduMap,
    BmNavigation,
    BmMapType,
    BmMarker,
    BmInfoWindow
  }
}
</script>

<style coped lang="scss">
.mapView {
  width: 100%;
  height: 270px;
  padding: 20px 0;
  ::v-deep .BMap_bubble_title {
    font-size: 12px !important;
  }
}
</style>
